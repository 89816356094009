import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";
import { wpImage } from "./fragments";

const indexPageData = () => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              isFrontPage
              template {
                ... on WpTemplate_FrontPage {
                  frontpageRichContent {
                    # SECTION: Hero
                    hero {
                      category
                      header
                      secondHeader
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      image {
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                        }
                      }
                    }

                    # SECTION: 5-grid cards
                    solution {
                      cards {
                        category
                        title
                        description
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                        items {
                          item {
                            title
                            description
                            background {
                              ...wpImageFluid
                            }
                            button {
                              variant
                              link {
                                target
                                title
                                url
                              }
                            }
                            icon {
                              ...wpImageFluid
                            }
                            options {
                              iconSize
                              iconPosition
                              color
                              backgroundColor
                              spanRows
                              spanCols
                              fullHeight
                            }
                          }
                        }
                        options {
                          display
                          cols
                          gap
                          textAlign
                          itemContentTextAlign
                          justify
                          align
                          justifyContent
                          alignContent
                        }
                      }
                    }

                    # SECTION: Logos
                    logosSection {
                      header
                      text
                      textAlign
                      slug
                      logos {
                        image {
                          ...wpImageFluid
                        }
                      }
                      logosAlign
                      numberOfRowsDesktop
                      numberOfColumnsMobile
                      button {
                        variant
                        link {
                          target
                          title
                          url
                        }
                      }
                      buttonAlign
                    }

                    # SECTION: Full Width Image
                    fullWidthImage {
                      imageSection {
                        category
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                        items {
                          category
                          title
                          text
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Image Section
                    imageSection {
                      category
                      title
                      text
                      slug
                      variant
                      image {
                        ...wpImageFluid
                      }
                      items {
                        category
                        title
                        text
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                    }

                    # SECTION: cards (single access)
                    singleAccess {
                      cards {
                        category
                        title
                        description
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                        items {
                          item {
                            title
                            description
                            background {
                              ...wpImageFluid
                            }
                            button {
                              variant
                              link {
                                target
                                title
                                url
                              }
                            }
                            icon {
                              ...wpImageFluid
                            }
                            options {
                              iconSize
                              iconPosition
                              color
                              backgroundColor
                              spanRows
                              spanCols
                              fullHeight
                            }
                          }
                        }
                        options {
                          display
                          cols
                          gap
                          textAlign
                          itemContentTextAlign
                          justify
                          align
                          justifyContent
                          alignContent
                        }
                      }
                    }

                    # SECTION: Our Clients
                    ourClients {
                      label
                      header
                      text
                      slug
                      slider {
                        authorOffice
                        authorName
                        text
                        logo {
                          altText
                          localFile {
                            id
                            extension
                            childImageSharp {
                              gatsbyImageData(height: 48, quality: 90)
                            }

                            childSvg {
                              content {
                                data
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return getCurrentRichContent(data, "frontpage", "frontpageRichContent");
};

export { indexPageData };
