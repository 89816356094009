// Default imports
import React from "react";

import GenericWideSection from "./GenericWideSection";
import OurClientsSlider from "../components/OurClientsSlider";

const OurClients = ({ slider, ...props }) => {
  return (
    <GenericWideSection vertical {...props}>
      <OurClientsSlider slides={slider} />
    </GenericWideSection>
  );
};

export default OurClients;
