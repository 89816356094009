import * as React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { indexPageData } from "../data/page-index.data";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Hero from "../componentsSections/Hero";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSection from "../components/ImageSection";
import CardsSection from "../components/CardsSection";
import ConnectivityLogos from "../components/ConnectivityLogos";
import OurClients from "../components/OurClients";

// Homepage
const IndexPage = ({ data: { wpPage } }) => {
  const seoPageData = getCurrentPageSeo("frontpage");

  const pageData = indexPageData();

  return (
    <>
      <Layout navbarVariant={pageData.hero.image ? "transparent" : undefined}>
        {/* SEO Metas */}
        <Seo post={seoPageData} />

        {/* Page head */}
        <Hero {...pageData.hero} textSize="big" />

        {/* Cards Sections */}
        <Section>
          <CardsSection {...pageData.solution?.cards} />
        </Section>

        {/* Logos Section */}
        <Section>
          <ConnectivityLogos
            containerClassName="pt-0"
            {...pageData.logosSection}
          />
        </Section>

        {/* Full-width Image Section */}
        <Section className="-mobile-full">
          <ImageSection {...pageData.fullWidthImage} />
        </Section>

        {/* Single Image Section */}
        <ImageSection
          containerClassName="pt-2"
          contentClassName="pt-3"
          imageSection={pageData.imageSection}
        />

        {/* Cards Sections */}
        <Section>
          <CardsSection {...pageData.singleAccess?.cards} />
        </Section>

        {/* Our Clients Section */}
        <Section>
          <OurClients containerClassName="py-0" {...pageData.ourClients} />
        </Section>

        {/* GetInTouch Section */}
        <Section className="-mobile-full">
          <GetInTouch />
        </Section>

        {/* News and insights Section */}
        <Section>
          <NewsAndInsights />
        </Section>
      </Layout>
    </>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query GET_PAGE {
    wpPage(isFrontPage: { eq: true }) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;
