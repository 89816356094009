// Default imports
import React, { forwardRef } from "react";

import Arrow24 from "../images/arrow-24.svg";

const SliderArrow = forwardRef(({ className, ...args }, ref) => {
  return (
    <span
      ref={ref}
      className={`slider-arrow ${className ? className : ""}`}
      {...args}
    >
      <Arrow24 className="sldier-arrow__icon" />
    </span>
  );
});

export default SliderArrow;
